@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply scroll-smooth;
}

body {
  @apply font-open-sans;
  background: rgba(128, 111, 166, .2);
}

header {
  @apply min-h-[40px] bg-theme flex items-center justify-center
}

nav {
  @apply min-h-[60px] flex items-center justify-center py-5 md:py-10;
}

main {
  @apply w-full flex flex-col gap-10 md:gap-20 items-center justify-center;
}

h1, h2, h3 {
  @apply text-black;
}

h1 {
  @apply font-merriweather text-6xl md:text-[70px] font-black;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply font-merriweather text-4xl md:text-5xl font-black;
}

footer {
  @apply bg-theme flex flex-col md:flex-row gap-4 md:gap-0 items-center justify-center py-10 px-5 md:px-0 text-white;
}

.header-title {
  line-height: 4rem;
  @apply mb-6;
}

.custom-shadow {
  box-shadow: 0 0 20px 0px rgba(128, 111, 166, .4);
}

.mask {
  mask: no-repeat url('assets/images/video-mask.svg');
}

.header {
  @apply hidden md:block;
  background: url('assets/images/bg-header.svg') no-repeat;
}

.map-container {
  @apply w-full h-[400px] overflow-hidden;
}

.nav-link {
  @apply block relative;
}

.nav-link::after {
  content: '';
  height: 0.1em;
  transition: opacity 300ms, transform 300ms;
  @apply bg-theme opacity-0 w-full left-0 bottom-0 absolute;
}

.nav-link:hover::after,
.nav-link:focus::after {
  @apply opacity-100;
  transform: translate3d(0, 0.2em, 0);
}

input, textarea {
  @apply border-2 border-theme border-opacity-50 rounded-full px-6 py-4 w-full;
}

input {
  @apply max-h-[60px];
}

.swiper {
  @apply !pb-10 !grid;
}

.swiper-wrapper {
  @apply !min-w-0;
}

.swiper-pagination {
  @apply bottom-0;
}

.swiper-pagination-bullet-active {
  @apply !bg-theme;
}